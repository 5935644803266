import {
  ActionMeta,
  GroupBase,
  Props as ReactSelectProps,
  createFilter,
} from 'react-select'
import { Message, useFormContext } from 'react-hook-form'
import React, { forwardRef } from 'react'

import AsyncSelect from 'react-select/async'
import DropdownIndicator from '@common/components/form/select/components/dropdown-indicator'
import { IFilterConfig } from '../select/index'
import { ISelectOption } from '../multi-select'
import { SelectWrapper } from '@common/components/form/select/styles'
import { classNames } from '@common/utils/helpers'
import { css } from '@emotion/react'
import customOption from '@common/components/form/select/components/option-custom'
import toolTipSingleValue from '@common/components/form/select/components/single-value-tooltip'
import { useCombinedRefs } from '@common/utils/use-hooks'
import { useIntl } from '@palqee/intl'

export interface PqAsyncSelectProps {
  borderRadius?: string
  inputMarginLeft?: number
  name?: string
  className?: string
  disabled?: boolean
  caption?: string
  placeholder?: string
  errorMessage?: Message
  errorBoard?: boolean
  loadOptions: any
  readOnly?: boolean
  value?: { value: string; label: string }
  isSearchable?: boolean
  isDropSelect?: boolean
  refRegister?: any
  autoComplete?: 'on' | 'off'
}

export const PqAsyncSelect: React.FC<
  PqAsyncSelectProps &
    Partial<ReactSelectProps<ISelectOption, boolean, GroupBase<ISelectOption>>>
> = forwardRef((props, forwardedRef) => {
  const intl = useIntl()
  const {
    borderRadius,
    inputMarginLeft,
    id,
    name,
    className,
    disabled,
    caption,
    placeholder = intl.formatMessage({
      id: 'SELECT',
      defaultMessage: 'Select',
    }),
    errorMessage,
    loadOptions,
    readOnly,
    value,
    errorBoard,
    isSearchable = false,
    refRegister,
    onChange,
    noOptionsMessage = () => (
      <span
        css={css`
          font-size: 11px;
        `}
      >
        {intl.formatMessage({
          id: 'NO_OPTIONS',
          defaultMessage: 'No options available',
        })}
      </span>
    ),
    ...rest
  } = props
  const { setValue } = useFormContext() ?? {}
  const classes = classNames('pq-select', className || '')

  const filterConfig: IFilterConfig = {
    ignoreCase: true,
    matchFrom: 'start',
  }

  const boardClasses = classNames(
    'pq-select-input-board',
    (disabled && '-disabled') || '',
    (errorMessage && '-error') || '',
  )
  const titleClasses = classNames(
    'pq-select-input-title',
    (disabled && '-disabled') || '',
  )

  const combinedRefs = useCombinedRefs(refRegister, forwardedRef)

  return (
    <SelectWrapper style={{ borderRadius }} inputMarginLeft={inputMarginLeft}>
      <div data-cy="form-async-select" className={boardClasses}>
        {caption && <span className={titleClasses}> {`${caption}`}</span>}
        <AsyncSelect
          menuPlacement="auto"
          onChange={(
            selected: ISelectOption,
            actionMeta: ActionMeta<ISelectOption>,
          ) => {
            if (onChange) {
              onChange(selected, actionMeta)
            }

            if (setValue) setValue(name, selected)
          }}
          onBlur={() => {
            if (setValue) setValue(name, value)
          }}
          id={id}
          className={classes}
          classNamePrefix="pq-select"
          placeholder={placeholder}
          noOptionsMessage={noOptionsMessage}
          name={name}
          components={{
            DropdownIndicator,
            SingleValue: (singleValueProps) => {
              return toolTipSingleValue(singleValueProps)
            },
            Option: customOption,
          }}
          loadOptions={loadOptions}
          instanceId={name}
          filterOption={createFilter(filterConfig)}
          menuIsOpen={readOnly ? false : undefined}
          isDisabled={disabled}
          isSearchable={isSearchable}
          ref={combinedRefs}
          cacheOptions
          defaultOptions
          styles={{
            control: (styles) => ({
              ...styles,
              height: '100%',
              borderRadius: '8px',
            }),
          }}
          value={value?.value ? value : null}
          {...rest}
        />
      </div>
      {errorBoard && (
        <div data-cy="form-async-select-error-msg" className="pq-error-message">
          {errorMessage}
        </div>
      )}
    </SelectWrapper>
  )
})
